import React from 'react'
import { MaxLayout } from '../components/Layout'
import { useUser } from '../models/auth'

const Welcome = () => {
  const user = useUser()
  return <p>Welcome{user ? `, ${user.name}` : ''}</p>
}

const Home = () => {
  return (
    <MaxLayout>
      <div>
        <h1>Home</h1>
        <Welcome />
      </div>
    </MaxLayout>
  )
}

export default Home
